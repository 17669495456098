import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFoundImage from '../components/notfoundimage'

const NotFoundPage = ({ location }) => {

  return (
    <Layout path={location}>
      <SEO title="404: Not found" />
       
          <NotFoundImage />
        
       
         
    </Layout>
  )
}

export default NotFoundPage

