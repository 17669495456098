import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'


const NotFoundImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "crying-baby.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage fluid={data.placeholderImage.childImageSharp.fluid}>
      <PerfectCenterWrapper>404 Page not Found aka "Shame on you!!"</PerfectCenterWrapper>
    </BackgroundImage>
  )
}

const PerfectCenterWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Ceviche One';
  font-size: 3rem;
  color: red;
  padding: 10px;
`

export default NotFoundImage
